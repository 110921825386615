import React,{useState} from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import Toast from '../../Toast'
import './refund.css'
import validator from 'validator'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'


const FreeConsult = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [isLoading,setIsLoading] = useState(false)
  const [contact,setContact] = useState({
    name:"",
    mobile:"",
    email:"",
    message:""
})
const handleChange = (e) => {

  setContact({...contact,[e.target.name]:e.target.value})
}
const Contact_form = async(e)=>{
  const {name, mobile, email, subject, message} = contact
  e.preventDefault()

   if( !email || !message || !mobile ) return Toast("please fill properly")
   if( !validator.isEmail(email)) return Toast("email is not valid")
   if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
 

   try{
    setIsLoading(true)
    const response= await axios({
      method: "post",
     url:'/contact',
      data:{
        name,mobile,email,subject:"for Consultaion ",message
      },

     })
     
     if(response.status===200){
      const data = response.data;
     
      Toast(data.message,response.status)
      
     }
   }
   catch(err){
    const error = err.response.data
    Toast(error.message)

   }
   finally{
    setIsLoading(false)
   }
}


    var arr =[
        {
          
            h1:"What if I just don’t like the product?",
            h6:"Managing Director",
            p:[
                'It’s simple: If you don’t love it, return it. We are committed to quality products and your satisfaction is 100% guaranteed.',
                'Our agent will be there at the time of delivery to install the art. Just fill out a simple form with the reasons you want to return and we will take care of all the work for you.',
                '   - Once you have seen the artwork, you will have to fill out the form at delivery.',
                '   - Special Collection & Limited Edition are FINAL SALE and not eligible for returns.',
                '   - Customers are responsible for return shipping costs.',

            ]
        },
 

    ]
  return (
    <>

<div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>REFUND POLICY</h1>
      </div>
      <div className='abc'>
      {
        arr?.map((element,index)=>{
            
            return <div className="free-consulting-conatiner1 container " style={index%2!=0 ? {flexDirection:'row-reverse'}:{flexDirection:'row'}}>
      <div style={{display: 'flex', alignItems:'center',justifyContent: 'center'}}>

        </div>
        <div className='content-freeconsult'>
            <h2 >{element?.h1}</h2>

            {element.p?.map((p_content)=>{

                return <p>{p_content}</p>
            })}
            

        </div>
      </div>
        })
      }
     



      </div>


      <div className="">
            <Link to='/contactus' className="link-a themeButton ">contact us</Link>
          </div>



    </>
  )
}

export default FreeConsult