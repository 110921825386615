import React,{useState,useContext,useEffect} from 'react'

import Carousel from 'react-bootstrap/Carousel'
import './Banner.css'
import { AuthContext } from '../../AuthProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../axios'
import Toast from '../../Toast'

const Banner = () => {
  const navigate = useNavigate();
  const {homepage,setHomepage,setCart,userToken} = useContext(AuthContext)
  const {Banner} = homepage
  const location =useLocation()


  const handleImageClick = () => {
    navigate('/ProductDetails');
  };

  const [isLoading,setIsLoading] =useState(false)
  
    const [crrItem, setCrrItem] = useState(0)

   

    const get_all= async(url,type) =>{

      try{
        setIsLoading(true)
        const response= await axios({
          method: "get",
         url:url,
         })
         
         if(response.status===200){
          const data = response.data;
          
            setHomepage((p)=>({...p,["Banner"]:data?.banners}))
           
  
          }
          
        //   Toast(data.message,response.status)
         
       }
       catch(err){
        const error = err.response.data
        Toast(error.message);
        
  
  
       }
       finally{
        setIsLoading(false)
       }
      }
  
      useEffect(()=>{
       !Banner.length? get_all('/get_all_banners','banner'):setIsLoading(false)
        
       
      },[])
      const Add_to_cart= async(id) =>{

        if(!userToken) return navigate('/Login',{state:{from :location} })
        const Form = new FormData()
        Form.append("product_id",id)
        Form.append("qty",1)
        try{
          setIsLoading(true)
          const response= await axios({
            method: "post",
           url:'/add-to-cart',
           data:Form,
           headers:{
            Authorization:`Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
            
           }
           })
           
           if(response.status===200){
            const data = response.data;
            setCart(data)
            Toast(data.message,response.status)
           }
         }
         catch(err){
          const error = err.response.data
          Toast(error.message);
          
      
      
         }
         finally{
          setIsLoading(false)
         }
        } 
   
  return (
<>
<div className='section-padding' >
  <div className="banner">
<Carousel variant="dark"  nextIcon={<img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/square-right.png"></img>}   prevIcon={<img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/square-left.png"></img>}interval={2000} >
 


     
      
  
    <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win1.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win2.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win3.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win4.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win5.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win6.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win7.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win8.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner">
        <img
    
          className="d-block w-100"
          loading="eager"
          src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/win9.jpg"
          alt="Third slide"
          id='banner'
          style={{objectFit: 'cover'}}
        />
        
    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>


    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      
    </Carousel>

</div>


<div className="banner1">
<Carousel variant="dark"  nextIcon={<img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/square-right.png"></img>}   prevIcon={<img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/square-left.png"></img>}interval={2000} >

     
      
 
          <Carousel.Item  className="text-banner" >
        <img
          // onClick={handleImageClick}
          className="d-block w-100"
          loading="eager"
          src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/mob1.jpg'
          alt="Third slide"
          id='banner1'
          style={{height:'70dvh',objectFit: 'cover'}}
        />

    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>

    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      


          <Carousel.Item  className="text-banner" >
        <img
          className="d-block w-100"
          loading="eager"
          src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/mob3.jpg'
          alt="Third slide"
          id='banner1'
          style={{height:'70dvh',objectFit: 'cover'}}
        />

    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>

    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
    
          <Carousel.Item  className="text-banner" >
        <img
          className="d-block w-100"
          loading="eager"
          src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/mob4.jpg'
          alt="Third slide"
          id='banner1'
          style={{height:'70dvh',objectFit: 'cover'}}
        />

    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>

    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
   
            
            
          <Carousel.Item  className="text-banner" >
        <img
          className="d-block w-100"
          loading="eager"
          src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/mob5.jpg'
          alt="Third slide"
          id='banner1'
          style={{height:'70dvh',objectFit: 'cover'}}
        />

    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>

    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner" >
        <img
          className="d-block w-100"
          loading="eager"
          src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/mob6.jpg'
          alt="Third slide"
          id='banner1'
          style={{height:'70dvh',objectFit: 'cover'}}
        />

    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>

    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      <Carousel.Item  className="text-banner" >
        <img
          className="d-block w-100"
          loading="eager"
          src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/mob7.jpg'
          alt="Third slide"
          id='banner1'
          style={{height:'70dvh',objectFit: 'cover'}}
        />

    
        <div className="bannerbg2">
           <div >

    <div className='d-flex' style={{ gridGap:'20px'}}>

    </div>
</div>  
        </div>
        
       
      </Carousel.Item>
      
    </Carousel>

</div>

</div>
</>

  )
}

export default Banner