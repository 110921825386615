import React from 'react'
import './footer.css'
// import logo from '../asset/image 1.png'
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../AuthProvider'


const Footer = () => {
    const navigate = useNavigate()
    const {All_Product_Page,Catagory} = useContext(AuthContext)
  return (
    <div className='footer ' style={{marginTop:'2rem'}}>
    <div className='main-footer d-flex' >
    <div className='footer-section' >
    <div Style={'width:100px; height:100px; border-radius:50% !important;background:white'}>
        <img src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/logo.png' alt="logo"  style={{width:'100%',height:'100%',objectFit:'contain',padding:'7px'}}></img>
        </div>
    </div>
<div className='footer-section'>
    <Link to="/aboutss">About us</Link>
    <Link to='/advisoryss'>Advisory</Link>
    <Link to='/privacyss '>Privacy Policy</Link>
    <Link to='/termsss'>Term & Conditions</Link>



    
    
   
   
</div>
<div className='footer-section'>
    
    <Link to='/'>PR Pages</Link>
   
    <Link to='/faqss'>FAQ's</Link>
    <Link to='/refundss'>Refund Policy</Link>
    <Link to='/shippingss'>Shipping Policy</Link>
    
    
   
   
</div>
<div className='footer-section'>
 
        <Link to="/AllPhoto">Gallery</Link>
    <Link to='/AllEvent'>Events</Link>
    <Link to='/career '>Careers</Link>
    <Link to='/AllBlog' >Blogs</Link>

   
   


    
    
</div>


<div className='footer-section'>
<h5>FOLLOW US ON</h5>
<div className='d-flex' style={{marginBottom:'1rem'}}>

<a href='https://www.instagram.com/grandioseartblr' target='_blank'><i className="bi bi-instagram px-1"></i></a>
<a href='https://www.facebook.com/grandioseartblr ' target='_blank'><i class="bi bi-facebook px-1"></i></a>
<a href='https://www.linkedin.com/company/82990164/admin/ ' target='_blank'><i class="bi bi-linkedin px-1"></i></a>
<a href='https://twitter.com/ArtGrandiose' target='_blank'><i class="bi bi-twitter px-1"></i></a>
<a href='https://www.youtube.com/channel/UCXzI1kDcNDCclPXZ6ga0Suw' target='_blank'><i class="bi bi-youtube px-1"></i></a>


</div>
<Link to="/contactus"><h5>CONTACT US</h5></Link>
    <p>+91 97404 50800</p>
    <a href='mailto:info@grandioseart.com' className='link-a' ><p>info@grandioseart.com</p></a>
    <a href='mailto:support@grandioseart.com' className='link-a' ><p>support@grandioseart.com</p></a>
    
    
</div>

    </div>
    <div className='bottom-line'>
        <p className='center-div ' style={{padding:'1.5rem',margin:0,color:'white'}}>© 2023 Grandiose Art. All Rights Reserved. Developed By Techrowth Pvt.Ltd</p>
    </div>
   
    </div>
  )
}

export default Footer