import React,{useState,useRef} from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import Toast from '../../Toast'
import './about.css'
import validator from 'validator'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'



const FreeConsult = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [isLoading,setIsLoading] = useState(false)
  const [contact,setContact] = useState({
    name:"",
    mobile:"",
    email:"",
    message:""
})
const handleChange = (e) => {

  setContact({...contact,[e.target.name]:e.target.value})
}
const readmore = useRef(null);
const readmore1 = useRef(null);


const [data,setdata] = useState("");

const readMore=(e)=>{
  var span = document.getElementById("showdesc1234")
  console.log(readmore)
  if(readmore.current.style['overflow']=="hidden"){
    readmore.current.style['overflow'] = "visible"
    readmore.current.style['height'] = "fit-content"
    span.innerText="Know Less"
    
  }   
  else{
    readmore.current.style['overflow'] = "hidden"
    readmore.current.style['height'] = "0px"
    span.innerText="Know More"
  } 
}

const readMore1=(e)=>{
  var span = document.getElementById("showdesc12345")
  console.log(readmore1)
  if(readmore1.current.style['overflow']=="hidden"){
    readmore1.current.style['overflow'] = "visible"
    readmore1.current.style['height'] = "fit-content"
    span.innerText="Know Less"
    
  }   
  else{
    readmore1.current.style['overflow'] = "hidden"
    readmore1.current.style['height'] = "450px"
    span.innerText="Know More"
  } 
}
const Contact_form = async(e)=>{
  const {name, mobile, email, subject, message} = contact
  e.preventDefault()

   if( !email || !message || !mobile ) return Toast("please fill properly")
   if( !validator.isEmail(email)) return Toast("email is not valid")
   if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
 

   try{
    setIsLoading(true)
    const response= await axios({
      method: "post",
     url:'/contact',
      data:{
        name,mobile,email,subject:"for Consultaion ",message
      },

     })
     
     if(response.status===200){
      const data = response.data;
     
      Toast(data.message,response.status)
      
     }
   }
   catch(err){
    const error = err.response.data
    Toast(error.message)

   }
   finally{
    setIsLoading(false)
   }
}


    var arr =[
      {
        images:'https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/testing.png',
        h1:"ABOUT US",
        h6:"“The aim of art is to represent not the outward appearance of things, but their inward significance”- Aristotle",
        p:[
           'Grandiose’s core idea is to create a platform that allows art admirers and enthusiasts to connect with various art styles and an ecosystem that allows budding artists to get the recognition and appreciation they deserve.Grandiose strongly believes that art should accessible to everyone to be truly appreciated.Situated in a peaceful and serene location, the Grandiose ArtGallery aims to create an immersive experience for art lovers where they can have intimate conversation with the artworks without any distractions from the outside world. Our founder, Simmi Prasanth had a vision to make beautiful paintings accessible and affordable to all,despite the common perception that art is expensive.We are proud to offer diverse collection of works from talented artists,carefully curated to showcase a range of styles and mediums. At Grandiose,we are committed to fostering love,appreciation for art in every person and creating vibrant community where art can thrive. We believe that art should be a part of everyones life and we are dedicated to making that a reality.',
        ]
    },
    {
      images:'https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/21.png',
      h1:"TEAM",
      h6:['Simmi Prasanth'],
      p:[
        
    '(Managing Director)',
        'Meet Simmi Prasanth, the visionary founder of our art gallery. With a passion for making art accessible to everyone, Simmi has created a space where paintings are not just a luxury for the elite, but an affordable and meaningful way to enhance any environment.',
        'Hailing from Kerala and with a Post Graduate degree in Computer Science, Simmi\'s love for art and interior design has always been a passion project. Her extensive travels across India and her innate curiosity to learn about different cultures led her to discover many talented artists. Simmi personal art collection soon became a source of inspiration for her unique approach to gifting, which was met with great enthusiasm. Today, her art gallery offers customised and concept-based wall art, art consultation and paintings that cater to the needs of the corporate sector, architects, and decorative art enthusiasts. Simmi s vision to make art an affordable luxury for everyone is at the heart of our gallery. Her dedication to bridging the gap between art and the public has made our gallery a destination for artists and art enthusiasts alike.'
      ]
  },
  {
    images:'https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv1.png',

    h6:['Bijayananda Biswaal'],
    p:[
      
    'Artistic Alias: Bijay Biswaal',
    '(Chief Advisory)',
      'Date of birth: May 16, 1964',
      'Birthplace: Odisha, India',
      '“Driven by an insatiable appetite for art, this obsessive painter has mastered all media and genres, both national and international. With a versatility that is unparalleled, he has earned a reputation that is lauded by none other than the Prime Minister himself. Currently serving as the Brand Ambassador of the iconic Winsor and Newton."'
    ]
},
     
 

    ]
  return (
    <>

<div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/aboutnew.png)`}}>
        <h1 className='h112'>ABOUT US</h1>
      </div>
      <div className='abc1212 container my-5'>
        <div className="aboutus11 d-flex">
          <div className="aboutus111" >
            <img className='ab11' src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/aboutus11.png"  alt="" />
          </div>
          <div className="aboutus112">
            <h3 className="outward" style={{textAlign: 'justify'}}>“THE AIM OF ART IS TO REPRESENT NOT THE OUTWARD APPEARANCE OF THINGS,<strong> BUT THEIR INWARD SIGNIFICANCE”</strong><br/> <br/> - ARISTOTLE</h3>
          <br />
          <br />
          <hr style={{color: '#009ba1',
              border: '2px solid #009ba1'}}/>
          <p style={{color:'#5B5B5B',textAlign: 'justify'}}>Grandiose’s core idea is to create a platform that allows art admirers and enthusiasts to connect with various art styles and an ecosystem that allows budding artists to get the recognition and appreciation they deserve. Grandiose strongly believes that art should accessible to everyone to be truly appreciated.Situated in a peaceful and serene location, the Grandiose Art Gallery aims to create an immersive experience for art lovers where they can have intimate conversation with the artworks without any distractions from the outside world. Our founder, Simmi Prasanth had a vision to make beautiful paintings accessible and affordable to all,despite the common perception that art is expensive. We are proud to offer diverse collection of works from talented artists,carefully curated to showcase a range of styles and mediums. At Grandiose, we are committed to fostering love,appreciation for art in every person and creating vibrant community where art can thrive. We believe that art should be a part of everyones life and we are dedicated to making that a reality.</p>
          </div>
        
        </div>
       
          <h1 className='team11 my-5'>TEAM</h1>
        <div className="team1">
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/team1.png" className='team1img' alt=""  style={{width:'45%'}}/>
        <div className="teamdetail">
          <div className="teamname">
            <h3>SIMMI PRASANTH</h3>
            <p style={{color:'#fff'}}>Managing Director</p>
          </div>
          <p style={{padding:'10%',color:'#5B5B5B',textAlign: 'justify'}}>Meet Simmi Prasanth, the visionary founder of our art gallery. With a passion for making art accessible to everyone, Simmi has created a space where paintings are not just a luxury for the elite, but an affordable and meaningful way to enhance any environment.
Hailing from Kerala and with a Post Graduate degree in Computer Science, Simmi's love for art and interior design has always been a passion project. Her extensive travels across India and her innate curiosity to learn about different cultures led her to discover many talented artists. Simmi personal art collection soon became a source of inspiration for her unique approach to gifting, which was met with great enthusiasm. Today, her art gallery offers customised and concept-based wall art, art consultation and paintings that cater to the needs of the corporate sector, architects, and decorative art enthusiasts. Simmi's vision to make art an affordable luxury for everyone is at the heart of our gallery. Her dedication to bridging the gap between art and the public has made our gallery a destination for artists and art enthusiasts alike.</p>
        </div>
        </div>
          <hr class="my-5" style={{color: '#009ba1',
              border: '2px solid #009ba1'}}/>
  <div className="team1">
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/team11111.png" className='team1img' alt=""  style={{width:'45%'}}/>
        <div className="teamdetail">
          <div className="teamname">
            <h3>BIJAY BISWAAL</h3>
            <p style={{color:'#fff'}}>Artist, Chief Advisory</p>
          </div>
          <p style={{padding:'10%',textAlign: 'justify',color:'#5B5B5B'}}>First Indian artist to feature prominently in nationally televised talk show MANN KI BAAT by honourable PM Mr Narendra Modi, year 2015<br/><hr style={{color: '#009ba1',
              border: '1px solid #009ba1'}}/>From a self-taught man in a remote corner of Odisha, to a railway worker, to an inspiring self-taught artist of national and international acclaim, Biswaal's journey has transformed perceptions of self-taught artists in the art world and among art lovers.<br/><hr style={{color: '#009ba1',
              border: '1px solid #009ba1'}}/>Among the ten selected artists from India participating in the Buddha-Gandhi-mother Teresa art show at Jakarta, Indonesia, in JUNE this year; Conducted in association with India high commission Indonesia.</p>
        </div>
        </div>


        <div className="team22">
          <div className="team222">
        <h2 className='free01'>BACKGROUND AND CAREER</h2>
        <p style={{color:'#5B5B5B',textAlign: 'justify'}}>Born and raised in Odisha, India, Bijay Biswaal's passion for art led him to start drawing at a young age. Although he pursued a career in the government sector, his love for art eventually drew him back and he quit his job to pursue it full-time. Through experimentation, he developed his own unique style of redefining realism, gaining recognition and becoming the first Indian Brand Ambassador for Winsor and Newton Art Company. Biswaal's exceptional talent has also earned him numerous awards and recognition from various art organizations.</p>
          </div>
          <div className="team222">
        <h2 className='free01'>STYLE AND INFLUENCES</h2>
<p style={{color:'#5B5B5B',textAlign: 'justify'}}>Biswaal's artwork is known for redefining realism. He uses vibrant colours and bold brushstrokes to create realistic yet surrealistic depictions of everyday life. His work is influenced by his childhood memories and experiences, as well as the culture and traditions of his home state. Biswaal has stated that he is also inspired by the works of Indian artists such as M.F. Hussain and Raja Ravi Varma, as well as European artists like Vincent van Gogh and Pablo Picasso.
Overall, Bijay Biswaal's journey from a self-taught artist to a successful painter and Brand Ambassador is a testament to the power of pursuing one's passion and following one's dreams. He has left his own mark in all the genres and mediums. He always maintains his best is yet to come.</p>          </div>

        </div>
        <hr class="my-5" style={{color: '#009ba1',
              border: '2px solid #009ba1'}}/>
          <h1 className='team11 my-5'>MAJOR ACHIEVEMENTS</h1>
<div className="ach container ">
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach1.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach2.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach3.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach4.png" alt="" />

</div>
<div className="ach container ">
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach5.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach6.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach7.png" alt="" />


</div>
<div className="ach container ">
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach8.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach9.png" alt="" />


</div>
<div className="ach container ">
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach10.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach11.png" alt="" />
  <img class="ai mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/ach12.png" alt="" />


</div>


<p className='driven'> “DRIVEN BY AN INSATIABLE APPETITE FOR ART, THIS OBSESSIVE PAINTER HAS MASTERED ALL MEDIA AND GENRES, BOTH NATIONAL AND INTERNATIONAL. WITH A VERSATILITY THAT IS UNPARALLELED, HE HAS EARNED A REPUTATION THAT IS LAUDED BY NONE OTHER THAN THE PRIME MINISTER HIMSELF. CURRENTLY SERVING AS THE BRAND AMBASSADOR OF THE ICONIC WINSOR AND NEWTON.”</p>
<div className="major my-5">
<h1 className='achieve' style={{color:'#5EBFC1',textAlign: 'center'}}>MAJOR ACHIEVEMENTS</h1>
<h4 className='achieve' style={{color:'#545454',textAlign: 'center'}}>ACRYLIC COLOR PAINTINGS</h4>
<div className="ach container ">
  <img  class="ii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv2.png"  alt="" />
  <img class="ii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv3.png" alt="" />
  <img class="ii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv4.png" alt="" />


</div>
<div className="ach container ">
  <img class="ii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv6.png" alt="" />
  <img class="ii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv7.png" alt="" />
  <img class="ii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/adv8.png" alt="" />


</div>
</div>
<div className="team1">
        <img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/team3 (3).png" className='team1img' alt=""  style={{width:'45%'}}/>
        <div className="teamdetail">
          <div className="teamname">
            <h3>MAHESH PATTRA</h3>
            <p style={{color:'#fff'}}>VISUAL ARTIST & CHIEF ART CONSULTANT</p>
          </div>
          <p style={{padding:'10%',color:'#5B5B5B',textAlign: 'justify'}}>Mahesh Pattra is a Bengaluru based Visual Artist and Educator. He is the founder of Art Realm Studio where he practices abstract painting, sculpting and wood art among many other interests. He enjoys bringing together artists, collaborators and learners in creating multidisciplinary art projects, one that breaks and makes new boundaries of what making art can be.
In educational spaces, his work has taken him to home-run art classes for children as well as schools, colleges, universities and even to Indian Institute of Science where he charters new territory every year by offering a course in Visual Arts to Science undergraduate students. Currently he is the Principal at Visthar Academy of Culture and Contemporary Studies, Doddagubbi, Bengaluru.</p>
        </div>
      
        </div>
     
<strong><h1 className='achieve' style={{color:'#5EBFC1',textAlign: 'left'}}>Artist Statement</h1></strong>
<div className="free11">
              <p style={{color:'#5B5B5B',textAlign: 'justify'}}>Art always surprises my curious mind irrespective of the outcome of making it. It always astonishes me how the limited canvas can be utilized in unlimited ways. It was quite early on itself, from my school days that I plunged head-first into expressing myself through art.<br/>
Like a playful child, my earlier works were purely an act of imprinting my visions on the canvas. Through the course of time and with the partaking of various educational qualifications and life experiences, now my paintings and other creations carry much more depth and girth in them, and aim to captivate the spectator’s curiosity as much as mine.<br/>
The fresh dialogue I experience with every work enables me to uncover new horizons and visions. Making art is my purpose for living.
Every work I start and finish is like “crossing another bridge."
</p>
              <p style={{color:'#5B5B5B',textAlign: 'justify'}}>In my works, the first glance shows abstractness, but going further, all that you’ll find is simply pure energy revealing the colors of my expressions. When I start a painting, I usually try keeping my mind blank. I believe that predetermination will confine one's creativity.<br/>
During the process, I emphasize on nurturing the transition of colours and allow time and space for it to grow. Before I realized, the work would have taken its own form! This play and growth of energy give me a foundation to improvise upon. Thus, every work of mine stands apart and on its own, for it brings out various emotions both for me and for the interpretative viewer.<br/>
Ultimately, I concur wholeheartedly with what Henry Miller beautifully says and quotes: “To make living itself is an art, that is the goal”.</p>
            </div>
            <hr class="my-5" style={{color: '#009ba1',
              border: '2px solid #009ba1'}}/>
<strong><h1 className='achieve' style={{color:'#5EBFC1',textAlign: 'left'}}>HIS WORKS</h1></strong>
<div className="ach container ">
  <img  class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+8.jpg"  alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+9.jpg" alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+10.jpg" alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+11.jpg" alt="" />
</div>
<div className="ach container ">
  <img  class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+12.jpg"  alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+13.jpg" alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+14.jpg" alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+15.jpg" alt="" />
</div>
<div className="ach container ">
  <img  class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+16.jpg"  alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+17.jpg" alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+18.jpg" alt="" />
  <img class="iii mx-3 my-5" src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/Frame+19.jpg" alt="" />
</div>
       </div>




    </>
  )
}

export default FreeConsult