import React,{useState} from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import Toast from '../../Toast'
import './faq.css'
import validator from 'validator'
import { useEffect } from 'react'

import { Link } from 'react-router-dom'

const FreeConsult = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [isLoading,setIsLoading] = useState(false)
  const [contact,setContact] = useState({
    name:"",
    mobile:"",
    email:"",
    message:""
})
const handleChange = (e) => {

  setContact({...contact,[e.target.name]:e.target.value})
}
const Contact_form = async(e)=>{
  const {name, mobile, email, subject, message} = contact
  e.preventDefault()

   if( !email || !message || !mobile ) return Toast("please fill properly")
   if( !validator.isEmail(email)) return Toast("email is not valid")
   if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
 

   try{
    setIsLoading(true)
    const response= await axios({
      method: "post",
     url:'/contact',
      data:{
        name,mobile,email,subject:"for Consultaion ",message
      },

     })
     
     if(response.status===200){
      const data = response.data;
     
      Toast(data.message,response.status)
      
     }
   }
   catch(err){
    const error = err.response.data
    Toast(error.message)

   }
   finally{
    setIsLoading(false)
   }
}


    var arr =[
        {
          
        
        },
     
 

    ]
  return (
    <>

<div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>FAQ'S</h1>
      </div>
      <div className='abc'>
      {
        arr?.map((element,index)=>{
            
            return <div className="free-consulting-conatiner1 container " >
      <div style={{display: 'flex', alignItems:'center',justifyContent: 'center'}}>

        </div>
        <div className='content-freeconsult123'>
            <h2 >FAQ</h2>
           
            
            <h6>Can I get a custom painting?</h6>
            <p>- Yes, we undertake custom paintings. For further information, please contact us with your requirements.</p>
            <p>- A quotation will be shared once we receive the original photograph.</p>
            <p>- Based on these details work we will give you the timeline.</p>

            <h6>Can I reserve the paintings?</h6>
            <p>- Yes, you can reserve the paintings. For reservations, please contact us.</p>
            <p>- Reserved paintings are only valid for 15 days.</p>

            <h6>Do you sell frames?</h6>
            <p>- Yes,</p>
            <p>       - Synthetic</p>
            <p>       - Wooden Frames</p>
            <p>       - Modern Frames</p>
            <p>       - Antique Frames</p>
            <p>       - Customize Framing options</p>
            <p>- For purchase-related queries, please contact us.</p>

            <h6>How to maintain a painting?</h6>
            <p>- Do not touch the painting</p>
            <p>- Keep away from the kids</p>
            <p>- Never use a wet cloth to wipe the dust</p>
            <p>- Use a feather duster twice a week to clean the artwork</p>
            <p>- Do not use a heavy vacuum cleaner</p>
            <p>- Use a soft half wet cloth to clean the frames</p>
            <p>- Keep away from direct sunlight</p>

            <h6>Can I sell my paintings?</h6>
            <p>- Yes, you can sell your paintings.</p>
            <p>- For further information, please contact us.</p>
            <h6>Can I buy Art material?</h6>
            <p>- No, we do not sell art materials.</p>
            <h6>Can I get my painting varnish from you?</h6>
            <p>- Yes, we do use varnish for protection for your paintings.</p>
            <p>- We have professional in-house Art experts who will guide you on what kind of varnish you must get done.</p>
            <p>- For further queries, please contact us .</p>
            
            <h6>Can I get an art consultant?

</h6>
            <p>- Yes, we have Art consultant experts, who will visit your place and understand the concept based on your interior theme & will suggest the best paintings for you.</p>
            <p>- Please check our services for more information</p>
            
         
      
            

        </div>
      </div>
        })
      }
     



      </div>


      <div className="">
            <Link to='/contactus' className="link-a themeButton ">contact us</Link>
          </div>



    </>
  )
}

export default FreeConsult