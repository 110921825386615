import React ,{useState,useEffect, useContext} from 'react'
import '../allproduct/Allproduct.css';

import { Link,useNavigate, useParams } from 'react-router-dom';

import { AuthContext } from '../../AuthProvider';

import Loader from '../Loader';
var arr =[
  {
      images:'https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/events123.jpg',
      h1:"Art Exhibitions & Showrooms",
      h6:"Managing Director",
      p:[
'To give you the best options to experience art we regularly organize art exhibitions, pop-up art galleries and open temporary art showrooms all around the world.',
'The purpose of each art gallery or exhibition is unique and driven by our artists’ ideas, the context of space, season and the vibes of the region. Meet the artists in person by joining an artist talk or a free art gallery event and get yourself inspired.'
      ]
  }
]
const AllProduct = () => {
  const navigate = useNavigate()
  const {All_Product_Page,Catagory,userToken,Cart,setCart} = useContext(AuthContext);
  const [isLoading,setIsLoading]=useState(false)

   

  return (
    <>
<div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>EVENTS</h1>
      </div>
      <div className='abc'>
      {
        arr?.map((element,index)=>{
            
            return <div className="free-consulting-conatiner1 container " style={index%2!=0 ? {flexDirection:'row-reverse'}:{flexDirection:'row'}}>
      <div style={{display: 'flex', alignItems:'center',justifyContent: 'center'}}>
        <img src={element?.images} id="free-consulting-img" />
        </div>
        <div className='content-freeconsult' style={{textAlign:'justify'}}>
            <h2 >{element?.h1}</h2>
            {element.p?.map((p_content)=>{

                return <p>{p_content}</p>
            })}
            

        </div>
      </div>
        })
      }
     



      </div>


      <div className="">
            <Link to='/contactus' className="link-a themeButton ">contact us</Link>
          </div>

      <h2><strong>Events:</strong></h2>
    {isLoading &&(<Loader />)}
  <div className="all-product section-padding">


   
    
<div className=" d-flex" style={{gridGap:'10px'}}>

  
 

    <div className="allproduct-right2">

    { 
      Catagory?.events?.map((element, index) =>{


return <div>
<Link to={'/Eventdetails/' + element?.id} key={index} className="link-a"><img src={ element?.images}></img> </Link>

<Link to={'/Eventdetails/' + element?.id} key={index} className="link-a"><p style={{fontWeight: '800',margin:0, padding:'10px'}}>{element.place}</p></Link>
<Link to={'/Eventdetails/' + element?.id} key={index} className="link-a"><p style={{fontWeight: '600',margin:0, padding:'5px'}}><i class="bi bi-calendar-check"></i>  {element.date}</p></Link>
<Link to={'/Eventdetails/' + element?.id} key={index} className="link-a"><p style={{fontWeight: '600',margin:0,padding:'5px'}}><i class="bi bi-alarm"></i> {element.time}</p></Link>
<Link to={'/Eventdetails/' + element?.id} key={index} className="link-a"><p style={{fontWeight: '600',margin:0,padding:'5px'}}><i class="bi bi-geo-alt-fill"></i> {element.address}</p></Link>


<Link to={'/Eventdetails/' + element?.id} key={index} className="link-a"><button className='viewmore1' style={{float: 'left',cursor:'pointer'}} > Read More </button></Link>

</div>

    })
  

    }

  </div>

  </div>
  </div>

    </>
  )
}

export default AllProduct