import React,{useState} from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import Toast from '../../Toast'
import './corporate.css'
import validator from 'validator'
import { Link,useNavigate,useParams } from 'react-router-dom';
import { useEffect } from 'react'


const FreeConsult = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [isLoading,setIsLoading] = useState(false)
  const [contact,setContact] = useState({
    name:"",
    mobile:"",
    email:"",
    message:""
})
const handleChange = (e) => {

  setContact({...contact,[e.target.name]:e.target.value})
}
const Contact_form = async(e)=>{
  const {name, mobile, email, subject, message} = contact
  e.preventDefault()

   if( !email || !message || !mobile ) return Toast("please fill properly")
   if( !validator.isEmail(email)) return Toast("email is not valid")
   if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
 

   try{
    setIsLoading(true)
    const response= await axios({
      method: "post",
     url:'/contact',
      data:{
        name,mobile,email,subject:"for Consultaion ",message
      },

     })
     
     if(response.status===200){
      const data = response.data;
     
      Toast(data.message,response.status)
      
     }
   }
   catch(err){
    const error = err.response.data
    Toast(error.message)

   }
   finally{
    setIsLoading(false)
   }
}



  return (
    <>


      <div className='abc'>
  

  <div className="allproduct-right2231">

  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/101.jpeg" className='tejas'  /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/102.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/103.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/104.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/105.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/106.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/107.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/108.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/109.jpeg"className='tejas'/></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/110.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/111.jpeg" className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/112.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/113.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/114.jpeg"className='tejas' /></Link>
  <Link to='/enquiry' ><img src="https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/115.jpeg"className='tejas' /></Link>
 

    </div>


      <div className="free-consulting-conatiner1 container " style={{flexDirection:'row-reverse'}}>
      <div style={{display: 'flex', alignItems:'center',justifyContent:'center'}}>
        <img src='https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/topimage.jpg' id="free-consulting-img" />
        </div>
        <div className='content-freeconsult'>
        <h2 >Have a Requirement? Get a Custom Quote</h2>
        

            <Form className='free-consult-form'>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Name" name='name' onChange={handleChange} />
                </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="number" placeholder="Phone" name='mobile' onChange={handleChange}/>
                </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Email" name='email' onChange={handleChange}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={6} name="message" onChange={handleChange}  />
                </Form.Group>
                <button className='themeButton mx-auto' onClick={Contact_form}> submit</button>
            </Form>
        </div>
      </div>
     

      </div>






    </>
  )
}

export default FreeConsult