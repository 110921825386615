import React,{useState,useEffect,useRef} from 'react'
import { Link } from 'react-router-dom'
import axios from '../../axios'
import Toast from '../../Toast'
import Loader from '../../component/Loader'


import './Aboutus.css'

const AboutUs = ({title,url}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [data,setdata] = useState("");
  const [isLoading,setIsLoading] = useState(true);
  const readmore = useRef(null);
  const readMore=(e)=>{
    var span = document.getElementById("showdesc1")
    console.log(readmore)
    if(readmore.current.style['overflow']=="hidden"){
      readmore.current.style['overflow'] = "visible"
      readmore.current.style['height'] = "fit-content"
      span.innerText="Know Less"
      
    }   
    else{
      readmore.current.style['overflow'] = "hidden"
      readmore.current.style['height'] = "500px"
      span.innerText="Know More"
    } 
  }

  const about= async(e)=>{
        
      
    try{
     setIsLoading(true)
     const response= await axios({
       method: "get",
      url:url,
       
       headers: {
         
         "Content-Type": "application/json",
         
       },
      })
      
      if(response.status===200){
       const data = response.data
       setdata(data?.about)
       
    
      
      }
    }
    catch(err){
     const error = err.response.data
     Toast(error.message);
     
 
 
    }
    finally{
     setIsLoading(false)
    }
 }

useEffect(()=>{

  about();
},[url])
  return (
    <>
     {isLoading && <Loader />}
        <top>
        <div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>{data?.name}</h1>
      </div>
     

      

  <p className='content' style={{overflow:'hidden',height:'500px',textAlign:`justify`}} ref={readmore} dangerouslySetInnerHTML={{__html: `${data?.content}`}}/> <span className='themeButton'  onClick={readMore} id="showdesc1" style={{cursor:'pointer' }}>Know More </span>



          <div className="">
            <Link to='/contactus' className="link-a themeButton ">contact us</Link>
          </div>
    
    </top>



    </>
  )
}

export default AboutUs