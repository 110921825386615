import React ,{useState,useEffect, useContext} from 'react'
import '../allproduct/Allproduct.css';

import { Link,useNavigate, useParams } from 'react-router-dom';

import { AuthContext } from '../../AuthProvider';

import Loader from '../Loader';

const AllProduct = () => {
  const navigate = useNavigate()
  const {All_Product_Page,Catagory,userToken,Cart,setCart} = useContext(AuthContext);
  const [isLoading,setIsLoading]=useState(false)

   

  return (
    <>
  <div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>ARTISTS</h1>
      </div>
    {isLoading &&(<Loader />)}
  <div className="all-product section-padding">



   
    
<div className=" d-flex" style={{gridGap:'10px'}}>


    <div className="allproduct-right121">

    { 
      Catagory?.artists?.map((element, index) =>{


return <div>
<Link to={'/Artistdetails/' + element?.id} key={index} className="link-a"><img src={ element?.images}></img> </Link>

<Link to={'/Artistdetails/' + element?.id} key={index} className="link-a"><p style={{fontWeight: '600',margin:0,position:'relative',bottom:'30px',left:'40px'}} className='artistname'>{element.name}</p></Link>

<Link to={'/Artistdetails/' + element?.id} key={index} className="link-a"><button className='viewmore' style={{float: 'right',cursor:'pointer',position:'relative',bottom:'22px'}} > Know More </button></Link>

</div>

    })
  

    }

  </div>

  </div>
  </div>

    </>
  )
}

export default AllProduct