import React ,{useState,useEffect, useContext} from 'react'
import '../allproduct/Allproduct.css';

import { Link,useNavigate, useParams } from 'react-router-dom';

import { AuthContext } from '../../AuthProvider';

import Loader from '../Loader';

const AllProduct = () => {
  const navigate = useNavigate()
  const {All_Product_Page,Catagory,userToken,Cart,setCart} = useContext(AuthContext);
  const [isLoading,setIsLoading]=useState(false)

   

  return (
    <>
   
    {isLoading &&(<Loader />)}
  <div className="all-product section-padding">



   
    
<div className=" d-flex" style={{gridGap:'10px'}}>

    <div className="allproduct-right1">

    { 
      Catagory?.artimages?.map((element, index) =>{


return <div>
<img src={ element?.image}></img> 







</div>
    })
  

    }

  </div>

  </div>
  </div>

    </>
  )
}

export default AllProduct