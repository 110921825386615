import React, { useContext, useState } from 'react'
import { useNavigate,Link,useLocation } from 'react-router-dom';
import './form.css'
// import form from '../assest/Rectangle 4007.png'
import Toast from '../../Toast'
import axios from '../../axios'
import { AuthContext } from '../../AuthProvider';
import Loader from '../../component/Loader';
import { useEffect } from 'react'



function Login() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const {setUserToken,setUserData} = useContext(AuthContext)
    let navigate = useNavigate();
    const location =useLocation();
  const from = location?.state?.from?.pathname
  console.log(from)
    const [selected, setSelected] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
 
    const [users, setUsers] = useState({
        email: "",
        password: ""

    });
    const { email, password } = users;
    const onchange = e => {
        // setErrorMsg('')
        setUsers(users => ({ ...users, [e.target.name]: e.target.value }));
    }

    
  const login = async(e)=>{
    e.preventDefault()

     if(!email || !password ) return Toast("please fill properly")
    
     try{
      setIsLoading(true)
      const response= await axios({
        method: "post",
       url:'/login',
        data:{
          email,password
        },
        headers: {
          "Content-Type": "application/json",
          
        },
       })
       
       if(response.status===200){
        const data = response.data
        setUserToken(data.accessToken);
        setUserData(data.Customer)
        window.localStorage.setItem('userToken', JSON.stringify(data.accessToken));
        window.localStorage.setItem('userData', JSON.stringify(data.Customer));
        Toast(data.message,response.status)
        navigate(from || '/')
       }
     }
     catch(err){
      const error = err.response.data
    //   console.log(error.status)
      if(error?.status==403){
// console.log('hello')
        resendotp();
       
        
        Toast(error.message);
        return 
      }
      Toast(error.message);
     
      
      


     }
     finally{
      setIsLoading(false)
     }
  }

  const resendotp=async(e)=>{
    try{
      setIsLoading(true)
        const response= await axios({
          method: "post",
         url:'/resend-otp',
          data:{
            email
          },
          headers: {
            "Content-Type": "application/json",
            
          },
         })
         
         if(response.status===200){
          const data = response.data
          navigate('/verifyotp',{state:{email}})
          Toast(data.message,response.status)
         }
       }
       catch(err){
        const error = err.response.data
        Toast(error.message)
  
       }
       finally{
        setIsLoading(false)
       }
}
    
    return (
       
      <>
      {isLoading ?<Loader />:null}
         
               <div className="center-div">
                <div className=' form-rightW1000 section-margin' style={{ padding: 0 }}>
                
                    <form className=' inputForm' onSubmit={login}
                        style={{}}>
                   
                        
                        <h3>Welcome to Art Gallery!</h3>
                        <span style={{fontWeight:600,color:'#757373b0'}}>Log in and  go to the <Link to="/" className='link-a' style={{color:'#009ba1'}}>Home</Link></span>
                        <br></br>
                        <br></br>
                        <div className="labelAndInput">
                            <label> Email </label>
                            <input type='email' placeholder="Enter email" name="email" value={users.email} onChange={e => onchange(e)}></input>
                           
                        </div>
                        <div className="labelAndInput">
                            <label> Password </label>
                            <input type='password' placeholder="Password" name="password" value={users.password} onChange={e => onchange(e)}></input>
                           
                        </div>

                        <div className='row justify-content-between'>
                            <div className='col-md-6' style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1.5px solid #aaa', height: 20, width: 20, borderRadius: 3, marginRight: 10, cursor: 'pointer' }}
                                    onClick={() => setSelected(!selected)}>
                                    {selected ?
                                        <i class="bi bi-check-lg" style={{ color: '#56BDBD' }}></i>
                                        : null}
                                </div>
                                <p style={{ color: '#000', margin: 0,fontWeight:600 }}>Remember me</p>
                            </div>
                            <div className='col-md-6' style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Link to='/forgetpassword' className='link-a' style={{fontWeight:600,color:'#757373b0'}}>Forgot password ?</Link>
                            </div>
                        </div>

                        <button type='submit' className='themeButton' style={{ width: '100%', marginTop: 20 }}>Log in</button>
                        <br></br>
                        <p style={{ color: '#000', textAlign: 'center',fontWeight:500,letterSpacing: '-0.017em' }}>Don't have an account? <Link to="/signup" className='link-a' style={{color: '#56BDBD'}}>Sign up</Link></p>

           

                    </form>
                </div>
                </div>
        
        </>
    )
}

export default Login