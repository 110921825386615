import React,{useState} from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import Toast from '../../Toast'
import './shipping.css'
import validator from 'validator'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'


const FreeConsult = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [isLoading,setIsLoading] = useState(false)
  const [contact,setContact] = useState({
    name:"",
    mobile:"",
    email:"",
    message:""
})
const handleChange = (e) => {

  setContact({...contact,[e.target.name]:e.target.value})
}
const Contact_form = async(e)=>{
  const {name, mobile, email, subject, message} = contact
  e.preventDefault()

   if( !email || !message || !mobile ) return Toast("please fill properly")
   if( !validator.isEmail(email)) return Toast("email is not valid")
   if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
 

   try{
    setIsLoading(true)
    const response= await axios({
      method: "post",
     url:'/contact',
      data:{
        name,mobile,email,subject:"for Consultaion ",message
      },

     })
     
     if(response.status===200){
      const data = response.data;
     
      Toast(data.message,response.status)
      
     }
   }
   catch(err){
    const error = err.response.data
    Toast(error.message)

   }
   finally{
    setIsLoading(false)
   }
}


    var arr =[
        {
          
        
        },
     
 

    ]
  return (
    <>

<div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>SHIPPING POLICY</h1>
      </div>
      <div className='abc'>
      {
        arr?.map((element,index)=>{
            
            return <div className="free-consulting-conatiner1 container " >
      <div style={{display: 'flex', alignItems:'center',justifyContent: 'center'}}>
        </div>
        <div className='content-freeconsult123'>
            <h2 >FREE shipping & other good things</h2>
            <p>If you want your stuff there faster, reach out to us with your order details.</p>
            <p></p>
            <h2>FAQs:</h2>
            <h6>When will I receive my artwork ?</h6>
            <p>As soon as we get your order, our team will reach out to you regarding the Artwork Package, Shipment & Installing.</p>
            <p></p>
            <h6>How will my artwork be packaged ?</h6>
            <p>All artwork is covered securely and placed in adjustable corrugated inserts that lock the piece in a secure position.</p>
            <p></p>
            <h6>How do I track my order ?</h6>
            <p>You will receive an Order Confirmation email after successfully placing an order. As soon as we ship your artwork, an associate from the Grandiose team will get in touch with you.</p>
            <p></p>
            <h6>
How will I get my artwork installed ? </h6>
            <p>Do not worry, an agent from our team will be there at the time of delivery who will take care of the art installation.</p>
            <p></p>
      
            

        </div>
      </div>
        })
      }
     



      </div>


      <div className="">
            <Link to='/contactus' className="link-a themeButton ">contact us</Link>
          </div>



    </>
  )
}

export default FreeConsult