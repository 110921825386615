import React ,{useState,useEffect, useContext} from 'react'
import '../allproduct/Allproduct.css';

import { Link,useNavigate, useParams } from 'react-router-dom';

import { AuthContext } from '../../AuthProvider';

import Loader from '../Loader';

const AllProduct = () => {
  const navigate = useNavigate()
  const {All_Product_Page,Catagory,userToken,Cart,setCart} = useContext(AuthContext);
  const [isLoading,setIsLoading]=useState(false)

   

  return (
    <>
    <div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>BLOGS</h1>
      </div>
    {isLoading &&(<Loader />)}
  <div className="all-product section-padding">


   
    
<div className=" d-flex" style={{gridGap:'10px'}}>

  
 

    <div className="allproduct-right1">

    { 
      Catagory?.blogs?.map((element, index) =>{


return <div>
<Link to={'/Blogdetails/' + element?.id} key={index} className="link-a"><img src={ element?.images}></img> </Link>
<div className='between-div m-3'>
<Link to={'/Blogdetails/' + element?.id} key={index} className="link-a"><h6 >{element.subject}</h6></Link>


</div>

<div className='between-div m-3'>


<Link to={'/Blogdetails/' + element?.id} key={index} className="link-a"><h6>{element.publish_date}</h6></Link>

</div>
<Link to={'/Blogdetails/' + element?.id} key={index} className="link-a"><button className='viewmore123' style={{float: 'left',cursor:'pointer'}} > Read More </button></Link>


</div>
    })
  

    }

  </div>

  </div>
  </div>

    </>
  )
}

export default AllProduct