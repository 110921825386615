import React,{useState,useRef} from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import Toast from '../../Toast'
import './privacy.css'
import validator from 'validator'
import { Link } from 'react-router-dom'

import { useEffect } from 'react'


const FreeConsult = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [isLoading,setIsLoading] = useState(false)
  const [contact,setContact] = useState({
    name:"",
    mobile:"",
    email:"",
    message:""
})
const handleChange = (e) => {

  setContact({...contact,[e.target.name]:e.target.value})
}
const readmore = useRef(null);
const readmore1 = useRef(null);


const [data,setdata] = useState("");

const readMore=(e)=>{
  var span = document.getElementById("showdesc1234")
  console.log(readmore)
  if(readmore.current.style['overflow']=="hidden"){
    readmore.current.style['overflow'] = "visible"
    readmore.current.style['height'] = "fit-content"
    span.innerText="Know Less"
    
  }   
  else{
    readmore.current.style['overflow'] = "hidden"
    readmore.current.style['height'] = "500px"
    span.innerText="Know More"
  } 
}
const Contact_form = async(e)=>{
  const {name, mobile, email, subject, message} = contact
  e.preventDefault()

   if( !email || !message || !mobile ) return Toast("please fill properly")
   if( !validator.isEmail(email)) return Toast("email is not valid")
   if( !validator.isMobilePhone(mobile)) return Toast("mobile is not valid")
 

   try{
    setIsLoading(true)
    const response= await axios({
      method: "post",
     url:'/contact',
      data:{
        name,mobile,email,subject:"for Consultaion ",message
      },

     })
     
     if(response.status===200){
      const data = response.data;
     
      Toast(data.message,response.status)
      
     }
   }
   catch(err){
    const error = err.response.data
    Toast(error.message)

   }
   finally{
    setIsLoading(false)
   }
}


    var arr =[
        {
          
        
        },
     
 

    ]
  return (
    <>

<div className="about-us-top" style={{background:`url(https://techrowth.s3.eu-north-1.amazonaws.com/GrandioseArt/images/abcdefg.jpg)`}}>
        <h1 className='h11'>PRIVACY POLICY</h1>
      </div>
      <div className='abc' style={{overflow:'hidden',height:'500px',textAlign:`justify`}} ref={readmore}>
      {
        arr?.map((element,index)=>{
            
            return <div className="free-consulting-conatiner1 container " >
      <div style={{display: 'flex', alignItems:'center',justifyContent: 'center'}}>

        </div>
        <div className='content-freeconsult123'>
           
           
            
           

            <h6>Who we are</h6>

            <p>Our website address is https://www.grandioseart.com.</p>

<h6>Comments</h6>

<p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>

<h6>Media</h6>

<p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.</p>

<h6>Cookies</h6>

<p>If you leave a comment on our site you may opt-in to saving your name, email address, and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</p>

<p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>

<p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed. </p>

<p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.</p>

<h6>Embedded content from other websites</h6>

<p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>

<p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

<h6>Who we share your data with</h6>

<p>If you request a password reset, your IP address will be included in the reset email.</p>

<h6>How long do we retain your data</h6>

<p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.</p>

<p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>

<h6>What rights do you have over your data</h6>

<p>If you have an account on this site or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>

<h6>Where do we send your data</h6>

<p>Visitor comments may be checked through an automated spam detection service.</p>

<h6>What we collect and store</h6>

<p>While you visit our site, we’ll track:</p>

<p>- Products you’ve viewed: we’ll use this to, for example, show you products you’ve recently viewed </p>

<p>- Location, IP address, and browser type: we’ll use this for purposes like estimating taxes and shipping </p>

<p>- Shipping address: we’ll ask you to enter this so we can, for instance, estimate shipping before you place an order, and send you the order!</p>

<p>We’ll also use cookies to keep track of cart contents while you’re browsing our site. </p>

<h6>Who on our team has access</h6>

<p>Members of our team have access to the information you provide us. For example, both Administrators and Shop Managers can access:</p>

<p>- Order information like what was purchased, when it was purchased and where it should be sent, and</p>

<p>- Customer information like your name, email address, and billing and shipping information.</p>

<p>Our team members have access to this information to help fulfill orders, process refunds and support you.</p>

<h6>What we share with others</h6>

<p>We share information with third parties who help us provide our orders and store services to you.</p>

<h6>Payments</h6>

<p>We accept payments through Razorpay. When processing payments, some of your data will be passed to Razorpay, including information required to process or support the payment, such as the purchase total and billing information. Please see the Razorpay Privacy Policy for more details.</p>
         
      
            

        </div>
      </div>
        })
      }
     



      </div><span className='themeButton' onClick={readMore} id="showdesc1234" style={{cursor:'pointer' }}>Know More </span>



      <div className="">
            <Link to='/contactus' className="link-a themeButton ">contact us</Link>
          </div>


    </>
  )
}

export default FreeConsult