import react ,{useState,useEffect,Suspense,lazy} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { CheckOut, } from './component/Exportfiles';
import { Footer, Navigationbar, Refund, Shipping, Faq} from './homepage/Exportfile';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PresistLogin from './pages/PresistLogin'
import RequiredLogin from './pages/Requiredlogin'
import Protected from './pages/Protected'

import ScrollToTop from './component/ScrollToTop';

import Loader from './component/Loader';
import FreeConsult from './pages/freeconsult/FreeConsult';
import Refundss from './pages/refund/refund';
import Shippingss from './pages/shipping/shipping';
import Faqss from './pages/faq/faq';
import Privacyss from './pages/privacy/privacy';
import Termsss from './pages/terms/terms';
import Aboutss from './pages/about/about';
import Advisoryss from './pages/advisory/advisory';



import Corporate from './pages/corporate/Corporate';

const HomePage= lazy(() => import( './homepage/HomePage'));
const OrderTab= lazy(() => import( './pages/orders/OrderTab'));
const OrderDetails= lazy(() => import( './pages/orders/OrderDetails'));
const Contact= lazy(() => import( './pages/contactus/Contact'));
const Career= lazy(() => import( './pages/career/Career'));

const Enquiry= lazy(() => import( './pages/enquiry/Enquiry'));

const AllProduct= lazy(() => import( './component/allproduct/AllProduct'));
const ProductDetails= lazy(() => import( './component/productDetails/ProductDetails'));
const Cart= lazy(() => import( './component/cart/Cart'));
const AllArtists= lazy(() => import( './component/artist/AllArtists'));
const ArtistDetails= lazy(() => import( './component/artist/ArtistDetails'));
const AllBlogs= lazy(() => import( './component/blog/AllBlogs'));
const BlogDetails= lazy(() => import( './component/blog/BlogDetails'));
const Artimages= lazy(() => import( './component/artimage/Artimages'));

const AllPhotos= lazy(() => import( './component/photo/AllPhotos'));
const PhotoDetails= lazy(() => import( './component/photo/PhotoDetails'));
const AllEvents= lazy(() => import( './component/event/AllEvents'));
const EventDetails= lazy(() => import( './component/event/EventDetails'));
const  Thanku= lazy(() => import( './component/thanku/Thanku'));
const  Help= lazy(() => import( './pages/help/Help'));
const  Forget= lazy(() => import( './pages/form/Forgot'));
const  Login= lazy(() => import( './pages/form/Login'));
const  Signup= lazy(() => import( './pages/form/Signup'));
const  Verify= lazy(() => import( './pages/form/Verify'));
const  AboutUs= lazy(() => import( './pages/aboutus/AboutUs'));


const Profile= lazy(() => import( './pages/profile/Profile'));
const top = () => {
  window.scrollTo(0,0);
}



function App() {

  return (
    <>

     <ScrollToTop />
     <Suspense fallback={<Loader />}>
     <Routes>
    
     <Route element={<Protected/>} >
     <Route path="/Login" element={<Login />} />
    <Route path="/forgetpassword" element={<Forget />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/verifyotp" element={<Verify />} />
    </Route>

    
    <Route element={<PresistLogin />} >
    <Route path="/" element={<><Navigationbar/><HomePage /><Footer /></>} />
    <Route path="/allproduct" element={<><Navigationbar/><AllProduct /><Footer  /></>} />
    <Route path="/allproduct/:catid" element={<><Navigationbar/><AllProduct /><Footer /></>} />
    <Route path="/AllArtist" element={<><Navigationbar/><AllArtists /><Footer /></>} />
    <Route path="/Artistdetails/:id" element={<><Navigationbar/><ArtistDetails /><Footer /></>} />
    <Route path="/AllBlog" element={<><Navigationbar/><AllBlogs/><Footer /></>} />
    <Route path="/Blogdetails/:id" element={<><Navigationbar/><BlogDetails/><Footer /></>} />
    <Route path="/Artimage" element={<><Navigationbar/><Artimages/><Footer /></>} />
    <Route path="/AllEvent" element={<><Navigationbar/><AllEvents/><Footer /></>} />
    <Route path="/Eventdetails/:id" element={<><Navigationbar/><EventDetails/><Footer /></>} />
    <Route path="/AllPhoto" element={<><Navigationbar/><AllPhotos/><Footer /></>} />
    <Route path="/Photodetails/:id" element={<><Navigationbar/><PhotoDetails/><Footer /></>} />
    <Route path="/ProductDetails/:id" element={<><Navigationbar/><ProductDetails /><Footer /></>} />
    <Route path="/help&support" element={<><Navigationbar/><Help /><Footer /></>} />
    <Route path="/freeConsulting" element={<><Navigationbar/><FreeConsult /><Footer /></>} />
    <Route path="/refundss" element={<><Navigationbar/><Refundss /><Footer /></>} />
    <Route path="/shippingss" element={<><Navigationbar/><Shippingss /><Footer /></>} />
    <Route path="/faqss" element={<><Navigationbar/><Faqss /><Footer /></>} />
    <Route path="/privacyss" element={<><Navigationbar/><Privacyss /><Footer /></>} />
    <Route path="/termsss" element={<><Navigationbar/><Termsss /><Footer /></>} />
    <Route path="/aboutss" element={<><Navigationbar/><Aboutss /><Footer /></>} />
    <Route path="/advisoryss" element={<><Navigationbar/><Advisoryss /><Footer /></>} />





    <Route path="/corporate" element={<><Navigationbar/><Corporate /><Footer /></>} />


    <Route path="/aboutus" element={<><Navigationbar/><AboutUs title={"About us"} url={"/about"} /><Footer /></>} />

    <Route path="/advisory" element={<><Navigationbar/><AboutUs title={"Advisory"} url={"/advisory"} /><Footer /></>} />
    <Route path="/shipping" element={<><Navigationbar/><AboutUs title={"Shipping Policy"} url={"/shipping"} /><Footer /></>} />
    <Route path="/faq" element={<><Navigationbar/><AboutUs title={"FAQ's"} url={"/faq"} /><Footer /></>} />
    <Route path="/refund" element={<><Navigationbar/><AboutUs title={"Refund"} url={"/refund"} /><Footer /></>} />


    <Route path="/Privacy_&_policy" element={<><Navigationbar/><AboutUs title={"Privacy & Policy "}  url={"/privacy_policy"}/><Footer /></>} />
    <Route path="/Terms_&_condition" element={<><Navigationbar/><AboutUs  title={"Terms & Condition"} url={"/terms"}/><Footer /></>} />




    <Route path="/career" element={<><Navigationbar/><Career/><Footer /></>} />
    <Route path="/enquiry" element={<><Navigationbar/><Enquiry/><Footer /></>} />





    <Route path="/contactus" element={<><Navigationbar/><Contact/><Footer /></>} />
    
    <Route path="/thanku" element={<><Navigationbar/><Thanku title="Congratulations" desc="Payment was successful and your order is confirmed Enjoy the excellent service and fast delivery." /><Footer /></>} />
    <Route path="/orderFailed" element={<><Navigationbar/><Thanku title="Order Unsuccesfull" desc="Call us Our Helpline number +917845126325 we will reach you soon" /><Footer /></>} />

    <Route element={<RequiredLogin />}>
    <Route path="/checkout" element={<><Navigationbar/><CheckOut/><Footer /></>} />
    <Route path="/cart" element={<><Navigationbar/><Cart /><Footer /></>} />
    
    <Route path="/profile" element={<><Navigationbar/><Profile/><Footer /></>} />
    <Route path="/orders" element={<><Navigationbar/><OrderTab/><Footer /></>} />
    <Route path="/OrderDetails/:id" element={<><Navigationbar/><OrderDetails/><Footer /></>} />
    
    </Route>
    </Route>

     </Routes>
    </Suspense>




     <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
draggable
pauseOnHover
theme="light"
/>
<div class='array'>

<a onClick={top} ><i class="bi bi-caret-up-fill"></i></a>
</div>
<div className="whatapp-float">

<a href='https://wa.me/+919740450800' target='_blank'>

<i class="bi bi-whatsapp" style={{color: 'white'}}></i> <span class="w-show"> Whatsapp</span>   

</a>

</div>

    </>
  );
}

export default App;
